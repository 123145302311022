<template>
  <section>
    <div v-if="loading" class="loading-spinner_container">
      <LoadingSpinner />
    </div>
    <div v-else>
      <h2>Vendors</h2>
      <Search v-model:text="this.search" />
      <ul class="vendors">
        <li
          v-for="vendor in getVendors"
          :key="vendor.id"
          class="vendors__details">
          <ImageCarousel
            :assets="getVendorCdnUrls(vendor.thumbnails)"
            :alt="vendor.name"
            is-clickable
            @click="
              this.$router.push({
                name: 'vendor',
                params: {
                  vendorCode: formatVendorCode(vendor),
                },
              })
            " />
          <router-link
            :to="{
              name: 'vendor',
              params: {
                vendorCode: formatVendorCode(vendor),
              },
            }">
            <div class="vendors__info">
              <p class="vendors__name">
                {{ vendor.name }}
              </p>

              <p
                v-if="vendor.cuisines.length"
                class="line-item vendors__cuisines">
                <Icon :path="mdiFood" :size="16" class="list-icon" />
                {{
                  vendor.cuisines
                    .sort((a, b) => b.id - a.id)
                    .map((c) => c.name)
                    .join(", ")
                }}
              </p>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
  import store from "@/store";
  import Enumerable from "linq";
  import { shuffle } from "@tucktrucks/core";
  import { mdiFood } from "@mdi/js";
  import Search from "@/components/search/Search.vue";
  import ImageCarousel from "@/components/Images/ImageCarousel.vue";

  export default {
    components: { Search, ImageCarousel },
    data() {
      return {
        vendors: null,
        loading: false,
        availableVendors: null,
        search: "",
        mdiFood,
      };
    },

    computed: {
      getVendors() {
        if (this.search == "") {
          return this.availableVendors;
        }

        const regex = new RegExp(this.search, "gi");

        return Enumerable.from(this.availableVendors)
          .where(
            (x) =>
              regex.test(x.name) ||
              regex.test(x.description) ||
              Enumerable.from(x.cuisines).any((y) => regex.test(y.name))
          )
          .toArray();
      },
    },

    methods: {
      getVendorCdnUrls(thumbnails) {
        return thumbnails.map(
          ({ cdnUrl }) => `${process.env.VUE_APP_CDN}` + "/" + cdnUrl
        );
      },

      formatVendorCode(vendor) {
        const code = vendor.code ? vendor.code.toLowerCase() : "";
        return code;
      },
    },

    async beforeMount() {
      this.$emit("cartHidden");

      this.loading = true;
      try {
        await store.state.apiPublic.client.endpoints.vendors
          .getAll()
          .then((response) => {
            const vendors = response.data.data;

            const availableVendors = [];
            const usedNames = []; // TEMP

            for (let vendor of vendors) {
              const { code, mode } = vendor;

              if (
                code &&
                mode == "Operational" &&
                !usedNames.includes(vendor.name)
              ) {
                availableVendors.push(vendor);
                usedNames.push(vendor.name);
              }
            }

            this.vendors = vendors;
            this.availableVendors = shuffle(availableVendors);
          });
      } catch (e) {
        window.log.error(e);
      } finally {
        this.loading = false;
      }
    },
  };
</script>

<style lang="scss">
  .vendors {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    @media screen and (min-width: $lg_desktop_max_width) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: $tablet_max_width) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: $mobile_max_width) {
      grid-template-columns: repeat(1, 1fr);
    }

    &__details {
      box-shadow: $card_shadow;
      border-radius: $card_radius;
      overflow: hidden;

      a {
        text-decoration: none;
      }
    }

    &__info {
      padding: 0.5rem 1rem 1rem;

      > * {
        color: $col_black;
      }

      .line-item {
        display: flex;
        font-weight: 300;
        font-size: 0.8rem;
        margin: 0;
        text-align: left;

        .list-icon {
          margin-right: 0.5rem;
        }
      }
    }

    &__name {
      font-size: 1.25rem;
      font-weight: bold;
      margin: 0;
    }

    &__description {
      font-size: 0.875rem;
      display: block;
      align-items: baseline;

      width: 100%;
      max-height: 68px;
      overflow: hidden;
      text-overflow: ellipsis;

      .icon-wrapper {
        top: 3px;
        margin-right: 5px;
        float: left;
      }
    }
  }
</style>
